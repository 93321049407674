<template>
    <!-- 点击折叠展开 -->
  
    <div class="taskrecursiontree"     @keyup.esc="canceladdtaskui" >
      <!-- 显示内容 -->
        
      <div v-if="model.root"></div>
      <div v-else>
      <div class="isnotroot">
      <div class="taskshowzone">
      <div class="taskbox" >
    <!-- <div class="round">
    <input type="checkbox" checked id="checkbox" />
    <label for="checkbox"></label>
    </div> -->
    <!-- <a-checkbox v-model:checked="taskchecked"  @change="updatetask(model)"></a-checkbox> -->
    <button  class="checkbox_version1"    @click="updatetaskfrombutton(model,$event)"  style="background:none;border:none" @mouseover="testhover=true" @mouseout="testhover=false">
      <div class="circlecheckbox" v-if="showcheckbox">
        <!-- <svg  v-show="testhover" width="22" height="22"><path fill="currentColor" d="M11.23 13.7l-2.15-2a.55.55 0 0 0-.74-.01l.03-.03a.46.46 0 0 0 0 .68L11.24 15l5.4-5.01a.45.45 0 0 0 0-.68l.02.03a.55.55 0 0 0-.73 0l-4.7 4.35z"></path></svg> -->
      <!-- <svg   v-show="testhover"   width="12" height="12" viewBox="0 0 12 12"><path fill="currentColor" d="M5.615 6.85l-1.075 -1a0.275 0.275 0 0 0 -0.37 -0.005l0.015 -0.015a0.23 0.23 0 0 0 0 0.34L5.62 7.5l2.7 -2.505a0.225 0.225 0 0 0 0 -0.34l0.01 0.015a0.275 0.275 0 0 0 -0.365 0l-2.35 2.175z"/></svg> -->
      <svg v-show="testhover"  width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M11.23 13.7l-2.15 -2a0.55 0.55 0 0 0 -0.74 -0.01l0.03 -0.03a0.46 0.46 0 0 0 0 0.68L11.24 15l5.4 -5.01a0.45 0.45 0 0 0 0 -0.68l0.02 0.03a0.55 0.55 0 0 0 -0.73 0l-4.7 4.35z"/></svg>
      </div>
    </button>
    <div @click="edittask" :class="tasktype" >
      <!--  var tagsfromfilter =   Object.keys(tags).filter((key) => tags[key] === "yes"); -->
      <span   
   
        :class="[tasktype, ...(model.tasktags ? Object.entries(JSON.parse(model.tasktags)).filter(([key, value]) => value === 'yes').map(([key]) => key) : [])]"

      class="taskcontent"  
      :draggable="dragabledata" @dragenter="dragenterfunc($event)" @dragleave="dragleavefunc($event)"  
        v-on:dragstart="drag(model,$event)"  v-on:drop="drop(model,$event)" 
        v-on:dragover="allowDrop($event)" >
         {{model.task}} 
      </span>
     </div>
       </div>
         <div class="toolbox" v-if="showtoolbox">
          <!-- <a class="button" href="#">明天</a> -->
          <Recomendicon v-if="checkmetadata(model?.Metadata) && tasktype === 'todaytask'"/>
           <Plusbutton  info="创建当前任务的子任务"   class="addchildtask" @click="addchildtask"></Plusbutton>

             
       <Todayword v-if="tasktype=='tomorrowtask'" class="todaytaskortomorrowtask"  @click="shecdule2today(model)"></Todayword>
      <Tomorrowword v-if="tasktype=='todaytask'"   class="todaytaskortomorrowtask"  @click="shecdule2tomorrow(model)"></Tomorrowword>
   
    <Giveupword class="giveuptask" @click="giveup(model)"></Giveupword>
    <Revokeword  @click="shecdule2tree(model)" class="revoketask" ></Revokeword>
    <quickmeloicon v-if="tasktype=='todaytask'" @click="generateQuickstartmelo(model)" class="quickstart" ></quickmeloicon>
       </div>
       </div>
       </div>
        <!-- 可以直接传入对应的taskid在这里 -->
        <taskedit  class="taskedit_zone"   @testchild="test"      v-on:turnoffchildbox = 'turnoffchildboxfunc'    v-if="addtaskbox" :parenttask="model" :action='action'   :tab = 'tab'> </taskedit>
      <!-- 显示折叠展开的图标，如果没有下级目录的话，则不显示 -->
       </div>
         <!-- 控制是否显示下级目录 -->
    <div v-show="open" v-if="isFolder">
      <!-- 重点代码，调用自身，实现递归，绑定数据 -->
      <!-- 是不是因该在这里直接缩进 -->
      <Item v-for="model in model.children"  :showtoolbox="showtoolbox"   :tasktype="tasktype"    :showcheckbox="showcheckbox"    :model="model" :key="model.task" ></Item>
    </div>
    
    </div>
  
</template>
<script>
import emitter from "../eventbus.js"; 
import configure_options from '../configure'
import Tomorrowword from '../svg/tomorrowword.vue'
import Todayword from '../svg/todayword.vue'
import Recomendicon from '../svg/recommendicon.vue'
import quickmeloicon from '../svg/quickmelo.vue'
import Giveupword from '../svg/giveupword.vue'
import Revokeword from '../svg/revoke.vue'
import Plusbutton from  '../svg/plusbutton.vue'
import taskedit from "./taskedit.vue"

// import Taskedit from './taskedit.vue'
const axios = require('axios').default;
import { defineComponent,ref } from 'vue';
export default defineComponent({
  name: "Item",
  components:{
    // Taskszone,
    Tomorrowword,
    Todayword,
    // Taskrecordzone,
    Giveupword,
    Revokeword,
    Plusbutton,
    Recomendicon ,
    quickmeloicon ,

    taskedit,
        //   Taskedit ,
     
  },
  emits:["refresh","didi"],
  // 如果想使用此组件，则需要传递的数据
  // props: {
  //   model: {
  //     type: Object,
  //     required: true
  //   }
  // },
  props:["model","showtoolbox","showcheckbox","tasktype"],
  
  watch:{
    // '$store.state.addchildtaskbox': function() {
    // console.log(this.$store.state.addchildtaskbox)
    // alert("状态发生")
  
  // }
  //  addchildtaskbox_status (newCount, oldCount) {
  //     // Our fancy notification (2).
  //     console.log("监听新信息")
  //     console.log(oldCount)
  //     console.log(newCount)
  //     if(newCount!=oldCount)
  //     alert(`We have ${newCount} fruits now, yay!`)
  //   }

},
  data() {
    return {
      // 默认不显示下级目录
      open: true,
      action:"edittask",
      tab:"todayortomorrowtab",
      addtaskbox:false,
      dragabledata:true,
      showtodayicon:true,
      showtomorrowicon:false,
    };
  },
  computed: {

  addchildtaskbox_status() {
      return this.$store.state.addchildtaskbox
      // Or return basket.getters.fruitsCount
      // (depends on your design decisions).
    },

    // 控制是否有下级目录和显示下级目录
    isFolder() {
      return this.model.children && this.model.children.length;
    }
  },
  methods: {
  
  turnoffchildboxfunc(e){
    
    if(e=="turnoff"){
      // alert(e)
    //  alert("要求关闭")
      this.addtaskbox = false;
    }
  },

    // 点击折叠展开的方法
    canceladdtaskui(){
    // this.addtaskbox = false
    this.addtaskbox = false;
    },



 
   checkmetadata(metadataJson) {
    if (!metadataJson) return false; // 如果没有元数据，返回false
    var metadata = JSON.parse(metadataJson); // 解析JSON字符串

    var today = new Date();
    var year = today.getFullYear();
    var month = String(today.getMonth() + 1).padStart(2, '0');
    var day = String(today.getDate()).padStart(2, '0');
    var yearLastTwoDigits = String(year).slice(-2);
    var todayFormatted =  yearLastTwoDigits + month + day;
    return metadata.recommendate === todayFormatted; // 返回recommendate属性的值是否为'240313'
},




   generateQuickstartmelo(task){
  
  
  
   var env =  configure_options["env"]
       
  console.log(configure_options["app_setting"][env])
  var serverhost =configure_options["app_setting"][env]["serverhost"]
  

  var spetask = {
          'starttime': 'unspecified',
          'deadline': 'unspecified',
          'endtime': 'unspecified',
          'ifdissect': 'no',
          'note': 'unspecified',
          'parentid': task.ID,
          'timedevotedto_a_task': "unspecified",
          'goal': 'read  100 books',
          'parentproject': 'unspecified',
          'inboxlist': 'empty',
          'inbox': "在该任务上投入5分钟进行热身",
          'finishtime': 'unspecified',
          'reviewalgolight': 'no',
          'reviewalgo': "unspecified",
          'place': 'unspecified',
          'input-time': 'Fri Aug 21 22:38:23 2020',
          'project': 'unspecified',
          'taskstatus': 'unfinished',
          'plantime': 'today',
          'taglight': 'yes',
          'tasktags': {"atomtag":"yes"},
          'client':"dreamforlifeweb",
          'tasktagsorigin': 'e'
        };
   axios.post(serverhost + '/v1/createtask', spetask)
    .then(response => {
        console.log(response.data)
        //可以将状态传入到父亲组件
        // alert(_this.tabinfo)
        emitter.emit("timercountsignal","on")
        emitter.emit("refreshtasks","today")

     
       

    })
    .catch(error=>{console.log(error)})
 },



    giveup(task){
    // alert(task.task)
   const  scheduleids = [task.ID]
   const postbody = {"giveuptaskids":scheduleids}
   var env =  configure_options["env"]
       
  console.log(configure_options["app_setting"][env])
  var serverhost =configure_options["app_setting"][env]["serverhost"]

   axios.post(serverhost +'/v1/giveuptasksbatch', postbody)
    .then(response => {
        console.log(response.data)
        emitter.emit("refreshtasks","today")
        emitter.emit("refresh_review","from_taskrecursion_component")
        emitter.emit("refresh_goaltree","taskrecusiontree_component_ask")
    })
    .catch(error=>{console.log(error)})




    },



    shecdule2today(task){
     //alert(task.ID)
        // alert("获取明天的消息")
   const  scheduleids = [task.ID]
   const postbody = {"todaytaskids":scheduleids}
   var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

   axios.post(serverhost +'/v1/todaytasksbatch', postbody)
    .then(response => {
        console.log(response.data)
        emitter.emit("refreshtasks","tomorrow")
        emitter.emit("refresh_review","from_taskrecursion_component")

    })
    .catch(error=>{
      console.log(error)
    alert("服务异常,稍后再试")
    })
    },


    shecdule2tomorrow(task){
     //alert(task.ID)
        // alert("获取明天的消息")
   const  scheduleids = [task.ID]
   const postbody = {"tomorrowtaskids":scheduleids}
   var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

   axios.post(serverhost +'/v1/tomorrowtasksbatch', postbody)
    .then(response => {
        console.log(response.data)
        emitter.emit("refreshtasks","today")
        emitter.emit("refresh_review","from_taskrecursion_component")

    })
    .catch(error=>{console.log(error)})
    },

     shecdule2tree(task){
        // alert(task.task)
            //alert(task.ID)
        // alert("获取明天的消息")
   const  scheduleids = [task.ID]
   const postbody = {"backtotreetaskids":scheduleids}
   var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

   axios.post(serverhost +'/v1/back2treetasksbatch', postbody)
    .then(response => {
        console.log(response.data)
        emitter.emit("refreshtasks","today")
        emitter.emit("refresh_review","from_taskrecursion_component")
        emitter.emit("refresh_goaltree","taskrecusiontree_component_ask")
    })
    .catch(error=>{console.log(error)})
    },
    addchildtask(){
        this.addtaskbox = true
        this.action = "addchildtask"
        // alert(this.tasktype)
        if (this.tasktype=="todaytask"){
          this.tab = "todaytab"
        }
        if (this.tasktype=="tomorrowtask"){
          this.tab = "tomorrowtab"
        }
        // alert( this.tab)
    },

    edittask(){
      // alert(1)
      this.addtaskbox = true
      this.action = "edittask"
    },

    toggle() {
      if (this.isFolder) {
        this.open = !this.open;
      }
    }
  },
  setup(props) {
  // const value1 = ref(500);
// console.log(Giveupword)
// console.log(Tomorrowword)
// console.log(Plusbutton)
    // this.$emit("refresh","today")
    var overelement;
    //  console.log(context)
    function updatetask(task) {
      // alert("更新任务")
      // context.emit('refresh',"today")
      // context.emit()
       
      console.log(task)
  //  alert( event.target.checked )
  // alert(this.taskchecked)
    if(this.taskchecked){
    //  alert("新修改的任务是")
    var devotedtime = 0
    // var selected_goal = "no goal"
    // alert(`修修改的任务是${this.editcontent}，是否checked${this.taskchecked}`)

     var reviewalgo = {
      'attackactively': 'no',
      'makeuseofthings': 'no',
      'depthfirstsearch': 'no',
      'markataskimmediately': 'no',
      'alwaysprofit': 'no',
      'brainuse': 'no',
      'useprinciple': 'no',
      'patience': 'no',
      'solveakeyproblem': 'no',
      'learnnewthings': 'no',
      'battlewithlowerbrain': 'no',
      'difficultthings': 'no',
      'acceptfactandseektruth': 'no',
      'dealwithahardtask': 'no',
      'threeminutes': 'no',
      'serviceforgoal': 'no',
      'buildframeandprinciple': 'no',
      'atomadifficulttask': 'no',
      'noflinch': 'no',
      'learntechuse': 'no',
      'doanimportantthingearly': 'no',
      'acceptpain': 'no',
      'conquerthefear': 'no',
      'life': 'no',
      'makearecord': 'no',
      'systematic':'no'
    }

       var updatedtask_info  = {
        'ifdissect': 'no',
        'deadline': 'unspecified',
        'starttime': 'unspecified',
        'endtime': 'unspecified',
        'tasktagsorigin': 'unspecified',
        'parentid': 'unspecified',
        'timedevotedto_a_task': devotedtime,
        'goal': task.goal,
        'client': 'dreamforlifeweb',
        'taglight': 'no',
        'note': 'unspecified',
        'reviewalgolight': 'yes',
        'reviewalgo': reviewalgo,
        'parentproject': 'unspecified',
        'id': task.ID,
        'place': 'unspecified',
        'finishtime': 'unspecified',
        'inbox': "nocontent",
        'project': "unspecified",
        'plantime': 'today',
        'taskstatus': 'finished',
        'tasktags': {}
        }
 var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]


   axios.post(serverhost+  '/v1/updatetask', updatedtask_info)
    .then(response => {
      this.taskchecked = false
      console.log(response.data)
      // alert("提交任务陈工")
      // context.emit('refresh',"today")
        //可以将状态传入到父亲组件
        emitter.emit("refreshtasks","today")
        if(updatedtask_info.taskstatus=="finished"){
           var svgdata =response.data.svgdata 
           var amazingday = response.data.amazingday 
           var amazingcount = response.data.amazingcount 
          emitter.emit("refreshtasksfinished",{type:"finishedtoday",data:svgdata,amazingday:amazingday,amazingcount:amazingcount})

        }
       
        emitter.emit("refresh_review","from_taskrecursion_component")
        // alert("检测左端出发")
    })
    .catch(error=>{console.log(error)})
    }
  
  }

 function updatetaskfrombutton(task,event) {
      // alert("更新任务")
      // context.emit('refresh',"today")
      // context.emit()
      
      this.taskchecked = true
      console.log(task)
  //  alert( event.target.checked )
  // alert(this.taskchecked)
    if(this.taskchecked){
    //  alert("新修改的任务是")
    var devotedtime = 0
    // var selected_goal = "no goal"
    // alert(`修修改的任务是${this.editcontent}，是否checked${this.taskchecked}`)

     var reviewalgo = {
      'attackactively': 'no',
      'makeuseofthings': 'no',
      'depthfirstsearch': 'no',
      'markataskimmediately': 'no',
      'alwaysprofit': 'no',
      'brainuse': 'no',
      'useprinciple': 'no',
      'patience': 'no',
      'solveakeyproblem': 'no',
      'learnnewthings': 'no',
      'battlewithlowerbrain': 'no',
      'difficultthings': 'no',
      'acceptfactandseektruth': 'no',
      'dealwithahardtask': 'no',
      'threeminutes': 'no',
      'serviceforgoal': 'no',
      'buildframeandprinciple': 'no',
      'atomadifficulttask': 'no',
      'noflinch': 'no',
      'learntechuse': 'no',
      'doanimportantthingearly': 'no',
      'acceptpain': 'no',
      'conquerthefear': 'no',
      'life': 'no',
      'makearecord': 'no',
      'systematic':'no'
    }

       var updatedtask_info  = {
        'ifdissect': 'no',
        'deadline': 'unspecified',
        'starttime': 'unspecified',
        'endtime': 'unspecified',
        'tasktagsorigin': 'unspecified',
        'parentid': 'unspecified',
        'timedevotedto_a_task': devotedtime,
        'goal': task.goal,
        'client': 'dreamforlifeweb',
        'taglight': 'no',
        'note': 'unspecified',
        'reviewalgolight': 'yes',
        'reviewalgo': reviewalgo,
        'parentproject': 'unspecified',
        'id': task.ID,
        'place': 'unspecified',
        'finishtime': 'unspecified',
        'inbox': "nocontent",
        'project': "unspecified",
        'plantime': 'today',
        'taskstatus': 'finished',
        'tasktags': {}
        }
 var env =  configure_options["env"]
       
        console.log(configure_options["app_setting"][env])
        var serverhost =configure_options["app_setting"][env]["serverhost"]

  event.target.style.backgroundColor="grey"
   axios.post(serverhost+  '/v1/updatetask', updatedtask_info)
    .then(response => {
      this.taskchecked = false
      console.log(response.data)
      // alert("提交任务陈工")
      // context.emit('refresh',"today")
        //可以将状态传入到父亲组件
        event.target.style.backgroundColor="grey"
        emitter.emit("refreshtasks","today")
        if(updatedtask_info.taskstatus=="finished"){
          var svgdata =response.data.svgdata 
          // var amazingday = response.data.amazingday 
          // var amazingcount =response.data.amazingcount
          emitter.emit("refreshtasksfinished",{type:"finishedtoday",data:svgdata})
        
        }
      
        emitter.emit("refresh_review","from_taskrecursion_component")
        // alert("检测左端出发")
    })
    .catch(error=>{console.log(error)})
    }
  
  }



  // deal with drage event
  function drag(model,event) {
  //   console.log("拖动的元素model is") 
  //   console.log(model)
  //   console.log(typeof model.tasktags)
  //   var tasktags = model.tasktags
  //   var obj = JSON.parse(tasktags)
  //   console.log(obj)


  //   const keysWithValueYes = Object.keys(obj).filter(key => obj[key] === "yes");
  // console.log(keysWithValueYes);
 
    var j = JSON.stringify(model);
     event.dataTransfer.setData("origintask", j);
    }

    function allowDrop(ev) {
      console.log("allowdrop被调用过")
    ev.preventDefault();
    console.log(overelement)
    // if(overelement!=null){

    //   overelement.backgroundColor= 'green';
    // }
    // ev.target.style.backgroundColor = "red"
    // overelement = ev.target
    }


    function  drop(model,ev) {
        ev.preventDefault();
        var origintask = JSON.parse(ev.dataTransfer.getData("origintask"));
        
        console.log(`获取到的数据为${origintask.ID}`)
        console.log(origintask)
        console.log(`放入的目标task id ${model.ID}`)
        var origintaskid =  origintask.ID
        var targetid = model.ID
        // ev.target.appendChild(document.getElementById(data));
        // 这里尝试更新数据的关系在这里
        var env =  configure_options["env"]
       
       console.log(configure_options["app_setting"][env])
       var serverhost =configure_options["app_setting"][env]["serverhost"]
       var updatedtask_info  = {
        'ifdissect': 'no',
        'deadline': 'unspecified',
        'starttime': 'unspecified',
        'endtime': 'unspecified',
        'tasktagsorigin': 'unspecified',
        'parentid': targetid,
        'timedevotedto_a_task': "unspecified",
        'goal': "unspecified",
        'client': 'dreamforlifeweb',
        'taglight': 'no',
        'note': 'unspecified',
        'reviewalgolight': 'yes',
        'reviewalgo': 'unspecified',
        'parentproject': 'unspecified',
        'id': origintaskid,
        'place': 'unspecified',
        'finishtime': 'unspecified',
        'inbox': "nocontent",
        'project':  "unspecified",
        'plantime': 'unspecified',
        'taskstatus': 'unfinished',
        'tasktags': {}
        }
        axios.post(serverhost+  '/v1/updatetask', updatedtask_info)
       .then(response => {
      this.taskchecked = false
      console.log(response.data)
      // alert("提交任务陈工")
      // context.emit('refresh',"today")
        //可以将状态传入到父亲组件
        ev.target.style.backgroundColor="transparent"
        emitter.emit("refreshtasks","today")
        // emitter.emit("refresh_review","from_taskrecursion_component")
        // alert("检测左端出发")
    })
    .catch(error=>{console.log(error)})






      }



     function dragenterfunc(e) {
      // console.log(val)
      // this.newDataItem = val
      e.preventDefault()
      e.target.style.backgroundColor = "red"
      }


      function dragleavefunc(e) {
      // console.log(val)
      // this.newDataItem = val
      console.log("离开函数")
      console.log(e.target)
      e.preventDefault()
      e.target.style.backgroundColor = "transparent"
      }

    

    return {
     
      testhover:ref(false),
      overelement:ref(null),
      updatetask,
      drag,
      allowDrop,
      drop,
      dragenterfunc,
      dragleavefunc,
      updatetaskfrombutton,
      taskid:props.model.ID,
      taskchecked: ref(false),
    };
  },
  mounted(){
    // alert(this.showtoolbox)
    // console.log(this.showtoolbox)
  
    emitter.on("trunofftaskedit",msg=>{
      // alert("检查是否被盗用")
        // alert
        if(msg=="from_taskedit_component"){
          this.addtaskbox = false;
        }
      })

  
  }
})
</script>


<style scoped lang="scss">

.pridetask{
  color:blue;
  pointer-events: none;
 
}

 .pridetask.taskcontent::after{
  color:blue;
  content:"😀"
}


// .taskcontent{
//   font-size: 14px;
 
// }

.taskshowzone{
  padding: 3px;
  font-size: 14px;
  // border:1px solid red;
  border-bottom-style:dotted;
  border-bottom-color:#a79999;
  border-bottom-width: 1px;
  // margin: 10px 50px 20px;
}



.taskrecursiontree{
  position:relative;
  // left:20px;
  margin-left:20px;
  // right: 0px;
  // width:100%;
  margin-right: 0;
  max-width: 40vw;
  max-height: 75vh;
  overflow:scroll;
}


.taskrecurisiontree{
    text-align: left;
}


.toolbox {  
  margin-top:4px;
  margin-left:8px;
  display: grid;
  grid-area:toolbox;
  width:90px;
  align-items: center;
  // justify-content: space-between;
    // align-items: center;
  grid-template-columns: 1fr  1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "addchildtask  todaytaskortomorrowtask giveuptask revoketask  quickstart recommend";
}

.toolbox > * {
  flex: 1; /* 使子项占据相同的空间 */
}
.taskbox{
  display: flex;
  grid-area: taskbox;
  margin-left: 2px;
  margin-right: 1px;
}



.taskshowzone{
  display: flex;
  /* grid-template-columns: auto;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "taskbox toolbox"; */
}

.addchildtask { grid-area: addchildtask; }

// .tomorrowtask { grid-area: tomorrowtask; }
.todaytaskortomorrowtask {
  grid-area: todaytaskortomorrowtask ; 
}
.giveuptask { grid-area: giveuptask; }

.revoketask{
  grid-area: revoketask; 
}

.quickstart{
  grid-area:quickstart;
}



.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 15px;
  left: 0;
  position: absolute;
  top: 0;
  width: 15px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #66bb6a;
  border-color: #66bb6a;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}


.taskedit_zone{
width: 80%;
}


#shorttime .hardtag{
  font-family: "goodfont";
  font-size:large;

}
@keyframes shake1 {
    0%, 100% {
        transform: translate(0, 0); /* 动画开始和结束时元素位置不变 */
    }
    25% {
        transform: translate(-5px, -5px); /* 向左上角移动 */
    }
    50% {
        transform: translate(5px, 5px); /* 向右下角移动 */
    }
    75% {
        transform: translate(-5px, 5px); /* 向左下角移动 */
    }
}
@keyframes fontEnlarge {
  0% {
    font-size: 16px; /* 初始字体大小 */
  }
  50% {
    font-size: 20px; /* 中间大小 */
    
  }
  100% {
    font-size: 24px; /* 最终字体大小 */
  }
}

#shorttime .hardtag:hover{
  transform:scale(1.5);
  color: white;
  background:rgb(206, 16, 41);
  // animation:shake1 0.5s alternate infinite ;
  animation: fontEnlarge 2s alternate ; 
}




.button {
  font-size: 12px;
  font-family: "Lucida Grande";
  text-decoration: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  color: white;
  display: inline-block;
  padding: 0.4em 1em;
  border: 0.1em solid #3e65b3;
  border-radius: 1.8em;
  background-image: linear-gradient(#62a6ed, #4b78d6); 
}
.button:hover {
  background-image: linear-gradient(rgb(26,175,238), rgb(4,132,217)); 
}
.button:active {
  background-image: linear-gradient(rgb(3,155,218), rgb(3,110,197)); 
}






.circlecheckbox{
  
    height:16px;
    width:16px;
    border:1px solid #dcdcdc;
    border-radius: 50%;
    overflow: hidden;
}

.checkbox_version1{
  display: flex;
      align-items: center;
  justify-content: center;
}

.circlecheckbox svg{
      margin-left: -6px;
      margin-top: -6px;
      pointer-events: none;
}

.pridetask{
    text-align: left;
}
</style>

